/*
 * @File:
 * @Autor: rongjiaxing
 * @Date: 2023-02-06 22:03:59
 * @LastEditors: rongjiaxing
 * @LastEditTime: 2023-02-06 22:11:47
 */
export default {
  //公开
  /**
   * 获取路径中的参数
   **/
  getParameter: function (url, variable) {
    var isContain = url.indexOf('?');
    if (isContain == -1) {
      return false;
    }
    var query = url.split('?')[1]; // 根据“?”切割数组，截取“?”之后的字符串
    // ['https://qq.com','appid=1234567890&name=Zdf']
    var vars = query.split('&'); // 根据“&”切割成数组
    // ['appid=1234567890','name=Zdf']
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      // ['appid','1234567890']
      if (pair[0] == variable) return pair[1]; // 没有花括号默认执行第一个js语句
    }
    return false;
  }
};
