/*
 * @File:
 * @Autor: rongjiaxing
 * @Date: 2023-01-29 15:12:00
 * @LastEditors: rongjiaxing
 * @LastEditTime: 2024-03-25 21:40:14
 */
module.exports = {
  messages: {
    menu: {
      orderList: 'List',
      gwc: 'Cart',
      ssnxydcphdm: 'Search',
      js: 'Search Menu',
      yyqh: 'Language',
      tableNum: 'Table number',
      dp: 'single item',
      tc: 'course',
      zizhu: 'Bottomless',
      hj: 'sum',
      ddls: 'history',
      zjdd: 'additional order',
      tjdgwc: 'Add to Cart',
      hot: 'Hot',
      yxgg: 'Selected Pattern',
      orderDetails: 'Order Details',
      cp: 'Menu',
      th: 'specials',
      hjjehs: 'Total price（tax included）',
      orderNum: 'Order number',
      sl: 'tax rate',
      createTime: 'Order Time',
      sfqdxd: 'Do you want to place your order?',
      cancel: 'Cancel',
      confirm: 'Confirm',
      zdxds: 'Maximum number of order at a time',
      fhzcd: 'Back to Menu',
      qrxd: 'Place orders',
      ksxd: 'Start Order',
      qrzf: 'Payment Confirmation',
      please_enter_the_number_of_people: 'Please enter the number of people',
      success: 'Success',
      error: 'Error',

      sjqqsb: 'Request failure',
      zwgggzh: 'No combination of specifications available',
      tjcg: 'Added successfully',
      fjx: 'option',
      gmfs: 'Purchase quantity',
      remark: 'note',
      qsrddbz: 'Input order notes',
      qsrssnr: 'Enter search keywords',
      noHistory: `You don't have an order history yet, hurry up to place an order！`,
      beverage:'Beverage',
      single:"Single item"

    }
  }
};
