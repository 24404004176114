/*
 * @File:
 * @Autor: rongjiaxing
 * @Date: 2023-01-29 19:41:46
 * @LastEditors: rongjiaxing
 * @LastEditTime: 2024-03-25 21:38:31
 */
module.exports = {
  messages: {
    menu: {
      orderList: '메뉴',
      gwc: '쇼핑 카트',
      ssnxydcphdm: '검색',
      js: '메뉴 검색',
      yyqh: '언어 전환',
      tableNum: '테이블 번호',
      dp: '단일 항목 메뉴',
      tc: '코스',
      zizhu: '뷔페',
      hj: '합계',
      ddls: '주문 내역',
      zjdd: '추가 주문',
      tjdgwc: '장바구니에 넣기',
      hot: '잘 팔리다',
      yxgg: '선택한 패턴',
      orderDetails: '주문 목록',
      cp: '메뉴',
      th: '스페시알스',
      hjjehs: '총 가격（세금）',
      orderNum: '주문 번호',
      sl: '세율',
      createTime: '주문 시간',
      sfqdxd: '주문하시겠습니까?',
      cancel: '취소',
      confirm: '확인',
      zdxds: '한 번에 최대 주문 수',
      fhzcd: '메뉴 돌아가기',
      qrxd: '주문하기',
      ksxd: '주문 시작',
      qrzf: '결제 확인',
      please_enter_the_number_of_people: 'Please enter the number of people',
      success: '주문 성공',
      error: '주문 실패',

      sjqqsb: '데이터 요청 실패',
      zwgggzh: '현재 해당 규격 조합이 없습니다',
      tjcg: '추가 성공',
      fjx: '항목',
      gmfs: '구매 수량',
      remark: '비고',
      qsrddbz: '주문 비고 입력',
      qsrssnr: '검색어 입력',
      noHistory: '아직 주문 내역이 없습니다. 지금 바로 주문하세요!',
      beverage:'술',
      single:"아이템"

    }
  }
};
