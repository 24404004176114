/*
 * @File:
 * @Autor: rongjiaxing
 * @Date: 2023-01-27 16:16:25
 * @LastEditors: rongjiaxing
 * @LastEditTime: 2023-02-12 20:59:53
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
var router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/pages/index'),
      meta: {
        keepAlive: true
      }
    },
    {
      path: '/menu',
      name: 'menu',
      component: () => import('@/pages/menu')
    },
    {
      path: '/details',
      name: 'details',
      component: () => import('@/pages/details')
    },
    {
      path: '/orderDetails',
      name: 'orderDetails',
      component: () => import('@/pages/orderDetails')
    },
    {
      path: '/carList',
      name: 'carList',
      component: () => import('@/pages/carList')
    }
  ]
});
export default router;
