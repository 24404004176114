/*
 * @File:
 * @Autor: rongjiaxing
 * @Date: 2023-02-06 21:49:36
 * @LastEditors: rongjiaxing
 * @LastEditTime: 2023-02-06 22:44:47
 */
//引入Vue核心库
import Vue from 'vue';
//引入Vuex
import Vuex from 'vuex';
//应用Vuex插件
Vue.use(Vuex);

//准备actions对象——响应组件中用户的动作
const actions = {};
//准备mutations对象——修改state中的数据
const mutations = {
  STARTDATA(state, value) {
    state.startData = value;
  }
};
//准备state对象——保存具体的数据
const state = {
  startData: {}
};

//创建并暴露store
export default new Vuex.Store({
  actions,
  mutations,
  state
});
