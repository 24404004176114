/*
 * @File:
 * @Autor: rongjiaxing
 * @Date: 2023-01-27 15:49:55
 * @LastEditors: rongjiaxing
 * @LastEditTime: 2023-03-26 21:30:45
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
// 导入 axios
import axios from 'axios'
// 导入 vueI18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
// import vConsole from './staic/vconsole';

import 'element-ui/lib/theme-chalk/index.css'
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  InputNumber,
} from 'element-ui'
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Input)
Vue.use(InputNumber)

import {
  Notify,
  Image as VanImage,
  Form,
  Field,
  Badge,
  Popup,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Stepper,
  Toast,
  Dialog,
  List,
  CellGroup,
  Loading,
} from 'vant'
Vue.use(Notify)
Vue.use(VanImage)
Vue.use(Form)
Vue.use(Field)
Vue.use(Badge)
Vue.use(Popup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Stepper)
Vue.use(Toast)
Vue.use(Dialog)
Vue.use(List)
Vue.use(CellGroup)
Vue.use(Loading)

/**
 * axios设置
 */
const $axios = axios.create()

$axios.interceptors.request.use((config) => {
  if (sessionStorage.getItem('Authorization')) {
    config.headers.Authorization = sessionStorage.getItem('Authorization')
  }
  return config
})

// 全局配置 axios 请求的根路径, 可以不写.
axios.defaults.baseURL = 'http://47.91.18.224:8080/api/'
// 把 axios 挂载到 Vue 原型上. 今后,在每个 .vue 组件中发起请求,直接调用 this.$http 即可.
Vue.prototype.$http = axios

//引入store
import store from './store'

import common from './common/common'
Vue.prototype.$common = common //其中$common就是调用时所使用的方法

console.log('lang:>> ', sessionStorage.getItem('lang'))
// 实例化 i8n对象
const language = sessionStorage.getItem('language') || 'Jap'
const i18n = new VueI18n({
  locale: language, // 设置地区
  messages: {
    zh: require('./staic/language/zh-CN'),
    en: require('./staic/language/en'),
    Jap: require('./staic/language/Jap'),
    ko: require('./staic/language/ko'),
  },
})

Vue.config.productionTip = false

/* 引入ajax请求方案 */
Vue.prototype.$ajax = function (config) {
  config.instance = this
  return $axios(config)
}

Vue.filter('formatMoney', (value) => {
  if (!value) return '0'
  // 将数据分割，保留两位小数
  value = (value - 0).toFixed(0)
  // 获取整数部分
  const intPart = Math.trunc(value)
  // 整数部分处理，增加,
  const intPartFormat = intPart
    .toString()
    .replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // 预定义小数部分
  let floatPart = '.00'
  // 将数据分割为小数部分和整数部分
  const newArr = value.toString().split('.')
  if (newArr.length === 2) {
    // 有小数部分
    floatPart = newArr[1].toString() // 取得小数部分
    return intPartFormat + '.' + floatPart
  }
  return intPartFormat
})

new Vue({
  render: (h) => h(App),
  router: router,
  i18n,
  store,
  // vConsole
}).$mount('#app')
