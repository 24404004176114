/*
 * @File:
 * @Autor: rongjiaxing
 * @Date: 2023-01-29 19:26:48
 * @LastEditors: rongjiaxing
 * @LastEditTime: 2024-03-25 21:39:08
 */
module.exports = {
  messages: {
    menu: {
      orderList: '履歴',
      gwc: 'カート',
      ssnxydcphdm: '検索',
      js: 'メニュー検索',
      yyqh: '言語切替',
      tableNum: '卓番',
      dp: '単品',
      tc: '定食・コース',
      zizhu: '放題',
      hj: '合計',
      ddls: '注文履歴',
      zjdd: '追加注文',
      tjdgwc: 'カートに追加',
      hot: '人気',
      yxgg: '選択したパータン',
      orderDetails: '注文情報',
      cp: '注文リスト',
      th: 'お得',
      hjjehs: '合計金額（税込み）',
      orderNum: '注文番号',
      sl: '税率',
      createTime: '注文時間',
      sfqdxd: '注文を確定しますか？',
      cancel: 'キャンセル',
      confirm: 'ok',
      zdxds: '一度に注文できる商品数は90点までです。',
      fhzcd: 'メニューへ戻る',
      qrxd: '注文を確定',
      ksxd: '注文を始める',
      qrzf: '支払確認',
      please_enter_the_number_of_people: '人数を入力下さい',
      success: '注文は成功しました',
      error: '注文は失敗しましたので、再度ご注文下さい',

      sjqqsb: 'データ要求に失敗しました',
      zwgggzh: '現在、この仕様の組み合わせはありません',
      tjcg: '追加しました',
      fjx: 'オプション',
      gmfs: '購入数量',
      remark: '備考',
      qsrddbz: '注文備考を入力下さい',
      qsrssnr: 'キーワードを入力下さい',
      noHistory: '注文履歴がありません。すぐ注文して下さい!',
      beverage: 'ドリンク',
      single: '単品',
    },
  },
}
