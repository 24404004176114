<template>
  <div id="app">
    <!-- <router-view></router-view> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    //挂载成功后给pop事件绑定一个方法
    // 如果支持 popstate （一般移动端都支持）
    if (window.history && window.history.pushState) {
      // 往历史记录里面添加一条新的当前页面的url
      history.pushState(null, null, document.URL);
      // 给 popstate 绑定一个方法用来监听页面返回
      window.addEventListener('popstate', this.back, false); //false阻止默认事件
    }
  },

  //组件销毁后清除事件
  destroyed() {
    window.removeEventListener('popstate', this.back, false); //false阻止默认事件
  },

  methods: {
    back() {
    }
  }

}
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;

}

* {
  margin: 0;
  padding: 0;
  touch-action: pan-y pan-x;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


}

input,
textarea {
  /* // fix： ios手机输入框焦点无法获取并输入值 */
  -webkit-user-select: auto;
}

[v-cloak] {
  display: none;
}

#app {
  width: 100%;
  height: 100%;
  font-family: MS Mincho;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eaeaea;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}

.searchInput .el-input__inner {
  background: none !important;
  border: none;
  padding: 0 0;
}

.noData {
  width: 100%;
}

.noData img {
  width: 100%;
  height: auto;
  display: inline-block;
}
</style>
