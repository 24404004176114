/*
 * @File:
 * @Autor: rongjiaxing
 * @Date: 2023-01-29 15:10:39
 * @LastEditors: rongjiaxing
 * @LastEditTime: 2024-03-25 21:37:03
 */
module.exports = {
  messages: {
    menu: {
      orderList: '列表',
      gwc: '购物车',
      ssnxydcphdm: '搜索',
      js: '菜品搜索',
      yyqh: '语言切换',
      tableNum: '座位号',
      dp: '单品',
      tc: '套餐',
      zizhu: '自助',
      hj: '合计',
      ddls: '订单历史',
      zjdd: '追加订单',
      tjdgwc: '添加到购物车',
      hot: '热销',
      yxgg: '已选规格',
      orderDetails: '订单详情',
      cp: '菜品',
      th: '特惠',
      hjjehs: '合计金额（含税）',
      orderNum: '订单号',
      sl: '税率',
      createTime: '订单时间',
      sfqdxd: '是否确定下单?',
      cancel: '取消',
      confirm: '确定',
      zdxds: '一次最大下单数90件',
      fhzcd: '回到主菜单',
      qrxd: '确定下单',
      ksxd: '开始下单',
      qrzf: '确认支付',
      please_enter_the_number_of_people: '请选择就餐人数',
      success: '下单成功',
      error: '下单失败',

      sjqqsb: '数据请求失败',
      zwgggzh: '暂无该规格组合',
      tjcg: '添加成功',
      fjx: '附加项',
      gmfs: '购买份数',
      remark: '备注',
      qsrddbz: '请输入订单备注',
      qsrssnr: '请输入搜索内容',
      noHistory: '您还没有历史订单呢，赶紧去下单吧！',
      beverage:'酒水',
      single:"组合单品"
    }
  }
};
